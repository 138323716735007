import { z } from "zod"

export enum RoleTypes {
    ADMIN = "admin",
    AUTHENTICATED = "authenticated",
    PUBLIC = "public",
}

export const RoleSchema = z.object({
    id: z.number(),
    name: z.string(),
    description: z.string(),
    type: z.nativeEnum(RoleTypes),
})
