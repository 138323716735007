import { ImageSchema, RoleType, IntentType } from "@energuide/shared"
import { z } from "zod"

export const SenderSchema = z.object({
    displayName: z.string().nullish(),
    avatar: ImageSchema.nullish(),
})

export type ISender = z.infer<typeof SenderSchema>

export const ChatSchema = z.object({
    displayKey: z.number().optional(), // used for key-prop only to keep rendering consistent
    id: z.number(),
    role: z.preprocess((val) => val as RoleType, z.string() as z.ZodType<RoleType>),
    intent_type: z.preprocess((val) => val as IntentType, z.string() as z.ZodType<IntentType>).nullish(),
    content: z.string().nullish(),
    sender: SenderSchema.nullish(),
    choices: z.array(z.string()).nullish(),
    files: z.array(ImageSchema),
    lastUpdate: z.coerce.date(),
    display: z.union([z.literal("default"), z.literal("cards")]),
    task_updates: z.array(z.any()),
})

export const ChatsSchema = z.array(ChatSchema)

export type IChat = z.infer<typeof ChatSchema>
