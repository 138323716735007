import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { ChatsSchema, IChat } from "@api/chat/schema"
import { transform as imageTransform } from "@api/image"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

export function transform(chat: { attributes: any; id: number }) {
    const user = chat.attributes.sender.data
    const avatar = user?.attributes.avatar.data
    const files = chat.attributes.files.data

    const transformed: IChat = {
        displayKey: chat.id,
        id: chat.id,
        role: chat.attributes.role,
        intent_type: chat.attributes.intent_type,
        content: chat.attributes.content,
        choices: chat.attributes.choices,
        sender: user
            ? {
                  displayName: user?.attributes.displayName ?? null,
                  avatar: imageTransform(avatar),
              }
            : null,
        files: files?.map(imageTransform) ?? [],
        lastUpdate: new Date(chat.attributes.updatedAt),
        display: chat.attributes.display ?? "default",
        task_updates: chat.attributes.task_updates ?? [],
    }
    return transformed
}

type IUseChats = {
    enabled?: boolean
}

export function useChats(projectId: number | null, params?: IUseChats) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["chats", projectId],
        queryFn: async () => {
            const { data } = await handleFetch(() =>
                fetch(`${apiUrl}/chats?projectId=${projectId}`, {
                    headers: {
                        ...authHeader,
                    },
                })
            )

            const chats = handleParse(() => ChatsSchema.parse(data.map(transform)))
            return chats
        },
        refetchOnMount: false,
        staleTime: Infinity,
        enabled: enabled && !!authHeader && !!projectId,
    })
}
