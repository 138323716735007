import { handleFetch, apiUrl } from "@api/apiConfig"
import { transform as imageTransform } from "@api/image"
import { AuthResponseSchema } from "./schema"

export type IConfirmation = {
    queryString: string
}

export async function confirmation(params: IConfirmation) {
    const { queryString } = params
    const response = await handleFetch(async () =>
        fetch(`${apiUrl}/auth/email-confirmation${queryString}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
    )

    const parsedAuthResponse = AuthResponseSchema.parse({
        ...response,
        user: {
            ...response.user,
            avatar: imageTransform(response.user.avatar),
        },
    })

    return parsedAuthResponse
}
