import { cls } from "@utils"
import React from "react"

type IHeading = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
    level: "h1" | "h2" | "h3" | "h4"
    className?: string
}

export function Heading(props: IHeading) {
    const { children, className, level, ...htmlRest } = props

    switch (level) {
        case "h1":
            return (
                <h1 {...htmlRest} className={`break-words font-heading text-heading1 text-white ${className}`}>
                    {children}
                </h1>
            )
        case "h2":
            return (
                <h2 {...htmlRest} className={`break-words font-heading text-heading2 text-white ${className}`}>
                    {children}
                </h2>
            )
        case "h3":
            return (
                <h3 {...htmlRest} className={`break-words font-heading text-heading3 text-white ${className}`}>
                    {children}
                </h3>
            )
        case "h4":
            return (
                <h3 {...htmlRest} className={`break-words font-heading text-heading4 text-white ${className}`}>
                    {children}
                </h3>
            )
    }
}

type TextVariant = "body1" | "body2" | "strong1" | "strong2" | "label" | "small" | "tiny"

type IText = React.PropsWithChildren<{
    className?: string
    style?: React.CSSProperties
    variant: TextVariant
}>

const variants: Record<TextVariant, string> = {
    body1: "font-text text-body1",
    body2: "font-text text-body2",
    strong1: "font-text text-strong1",
    strong2: "font-text text-strong2",
    label: "font-heading text-label",
    small: "font-text text-small",
    tiny: "font-text text-tiny",
}

export const Text = React.forwardRef<HTMLParagraphElement, IText>((props, ref) => {
    const { children, className, variant, style } = props
    return (
        <p className={cls(variants[variant], "overflow-hidden text-ellipsis", className)} ref={ref} style={style}>
            {children}
        </p>
    )
})
