import { useReadyForReview } from "@api/certificate/useReadyForReview"
import { useCreateProject } from "@api/projects/useCreateProject"
import { useProjects } from "@api/projects/useProjects"
import { useUser } from "@api/user/useUser"
import { Button, LinkButton } from "@components/atoms/buttons"
import { MenuIcon } from "@components/atoms/icons"
import { Text } from "@components/atoms/typography"
import { UserAvatar } from "@components/molecules/avatar"
import { ProjectList } from "@components/molecules/project-list"
import { RoleTypes } from "@energuide/shared"
import { useMobile } from "@hooks/useMobile"
import { useSafeArea } from "@hooks/useSafeArea"
import { useAppState } from "@hooks/useState"
import * as Dialog from "@radix-ui/react-dialog"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"
import { cls } from "@utils"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"

type ISideMenu = {
    className?: string
}

function SideMenu(props: ISideMenu) {
    const { className } = props
    const inset = useSafeArea()
    const projectId = useAppState((state) => state.activeProjectId)
    const navigate = useNavigate()
    const { data } = useProjects()
    const { data: user } = useUser()
    const { displayName = "", email = "", avatar, role } = user ?? {}
    const { url = "" } = avatar ?? {}
    const isAdmin = role?.type === RoleTypes.ADMIN

    const { data: drafts } = useReadyForReview({ enabled: isAdmin })

    const createProjectMutation = useCreateProject()

    const onAddProject = async (data: FormData) => {
        const result = await createProjectMutation.mutateAsync({
            name: data.get("name")?.toString() ?? "",
        })

        const { id } = result.data
        if (id) {
            navigate(`/authenticated/chat/${id}`)
        }
    }

    return (
        <motion.div
            style={{
                paddingTop: `${inset.top}px`,
                paddingBottom: `${inset.bottom}px`,
                paddingLeft: `${inset.left}px]`,
            }}
            className={cls("grid h-dvh grid-rows-[minmax(0,1fr)_auto_auto] bg-backgroundStrong", className)}
            initial={{ x: "-100%" }}
            animate={{ x: "0%" }}
            exit={{ x: "-100%" }}
            transition={{
                ease: "circInOut",
            }}
            data-testid="menu-container"
        >
            <ProjectList projects={data ?? []} selectedId={projectId ?? 0} onAdd={onAddProject} />

            <div className="border-b-1 grid grid-cols-[minmax(0,1fr)] gap-4 border-solid border-y-backgroundLight p-6">
                <div className="relative grid gap-4">
                    <UserAvatar
                        src={url ?? ""}
                        alt=""
                        initials={displayName?.[0] ?? ""}
                        variant="menu"
                        className="outline outline-1 outline-offset-[-1px] outline-white/10"
                    />
                    <div className="grid grid-cols-[1fr_auto] gap-4">
                        <div className="overflow-hidden">
                            <Text variant="strong2" className="text-text">
                                {displayName} {isAdmin && "(Admin)"}
                            </Text>
                            <Text variant="small" className="overflow-hidden text-ellipsis text-textVeryLight">
                                {email}
                            </Text>
                        </div>

                        <LinkButton
                            href="/authenticated/account"
                            variant="tertiary"
                            className="border-1 aspect-square rounded-full border border-text/20 p-2 text-text/40"
                            dataTestId="menu-button"
                        >
                            <i className="ri-edit-box-fill text-[1.5rem] leading-none"></i>
                        </LinkButton>
                    </div>
                </div>
                {isAdmin && (
                    <>
                        <LinkButton variant="secondary" href="/authenticated/reviews">
                            Reviews{drafts?.length ? ` (${drafts.length})` : ""}
                        </LinkButton>
                        <LinkButton variant="secondary" href="/authenticated/project-debug">
                            Debug
                        </LinkButton>
                    </>
                )}
                <footer className="flex gap-4">
                    <LinkButton
                        href="https://enerithm.com/terms-and-conditions"
                        className="text-tiny"
                        variant="freeform"
                        isExternal={true}
                    >
                        AGB
                    </LinkButton>
                    <LinkButton
                        href="https://enerithm.com/data-protection"
                        className="text-tiny"
                        isExternal={true}
                        variant="freeform"
                    >
                        Datenschutz
                    </LinkButton>
                    <LinkButton
                        href="/licenses"
                        className="text-tiny"
                        isExternal={false}
                        variant="freeform"
                    >
                        Licenses
                    </LinkButton>
                    {/* <p className="overflow-hidden text-ellipsis text-tiny text-textVeryLight">{commitHash}</p> */}
                </footer>
            </div>
        </motion.div>
    )
}

type IMenu = {
    mode: "mobile" | "desktop"
}

export function Menu(props: IMenu) {
    const { mode } = props
    const isMobileMode = mode === "mobile"
    const isMobile = useMobile()
    const [open, setOpen] = React.useState<boolean>(true)
    const location = useLocation()

    React.useEffect(() => {
        setOpen(false)
    }, [location.pathname])

    if (isMobileMode && !isMobile) {
        return <div className="w-10"></div>
    }

    if (!isMobileMode) {
        return <SideMenu />
    }

    return (
        <Dialog.Root onOpenChange={setOpen} open={open} modal>
            <Dialog.Trigger asChild>
                <Button variant="tertiary" className="text-strong2 text-text" data-testid="menu-button">
                    <MenuIcon />
                </Button>
            </Dialog.Trigger>
            <AnimatePresence>
                {open && (
                    <Dialog.Portal forceMount>
                        <Dialog.DialogOverlay
                            className="fixed bottom-0 left-0 right-0 top-0"
                            onClick={() => setOpen(false)}
                        >
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{
                                    ease: "circInOut",
                                }}
                                className="h-full w-full bg-background/50 backdrop-blur-sm"
                            ></motion.div>
                        </Dialog.DialogOverlay>

                        <Dialog.Content forceMount>
                            <VisuallyHidden.Root asChild>
                                <Dialog.Title>Menü</Dialog.Title>
                            </VisuallyHidden.Root>
                            <VisuallyHidden.Root asChild>
                                <Dialog.Description>Hauptmenü der Anwendung</Dialog.Description>
                            </VisuallyHidden.Root>
                            <SideMenu className="fixed bottom-0 top-0 z-20" />
                        </Dialog.Content>
                    </Dialog.Portal>
                )}
            </AnimatePresence>
        </Dialog.Root>
    )
}
